import axios, { AxiosError } from 'axios';
import { refreshTokenResponse } from 'interfaces/Signer';
import store from 'store';
import { clearGroupAndPermissions } from 'store/ducks/Groups';
import { clearSignin, setRefreshToken } from 'store/ducks/Signer';

const api = axios.create({
  baseURL: process.env.REACT_APP_END_POINT,
  timeout: 60000,
});

api.interceptors.request.use(
  (request) => {
    const { token } = store.getState().signer.signin;
    if (token) {
      api.defaults.headers.common.Authorization = `Bearer ${token}`;
    }
    return request;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error: AxiosError) => {
    if (error.response?.status === 401) {
      const { currentRefreshToken } = store.getState().signer.signin;
      const res = await api.post<refreshTokenResponse>('/signin/refreshToken', {
        refreshToken: currentRefreshToken,
      });
      if (res.status === 200) {
        store.dispatch(setRefreshToken({ token: res.data.token }));
        return Promise.resolve(api.request(error.config));
      }
      store.dispatch(clearSignin());
      store.dispatch(clearGroupAndPermissions());
    } else if (error.response?.status === 403) {
      store.dispatch(clearSignin());
      store.dispatch(clearGroupAndPermissions());
    }
    return Promise.reject(error);
  },
);

export const apiRecovery = axios.create({
  baseURL: process.env.REACT_APP_RECOVERY,
});

export default api;
